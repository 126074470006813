import React, { Fragment, useContext } from 'react'
import { Layout, Button, Row, Col } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

import { GlobalContext } from '../../mainContext'
const AntHeader = Layout.Header

const Header = () => {
   //context declare
   let { globalState, globalDispatch } = useContext(GlobalContext)

   return (
      <Fragment>
         {/* header ----------------------------------------------------------- */}

         <AntHeader theme='light' style={{ padding: 0 }}>
            <Row>
               <Col span={8}>
                 
                  {globalState.MenuCollapsed == true && (
                     <div
                        style={{
                           textAlign: 'left',
                           marginLeft: 20,
                           marginBottom: 0,
                           marginTop: 0,
                        }}
                     >
                        <img
                           src={require('../../assets/image/app-logo-collap.png')}
                        />
                     </div>
                  )}
               </Col>
               <Col span={2}></Col>
               <Col span={14}>
                  <div
                     style={{
                        textAlign: 'right',
                        marginRight: 20,
                     }}
                  >
                     <Button
                        type='primary'
                        danger
                        onClick={() => {
                           //logout
                           //DoLogout()
                           globalDispatch({ type: 'LOG_OUT' })
                        }}
                     >
                        <LogoutOutlined />
                        Logout
                     </Button>
                  </div>
               </Col>
            </Row>
         </AntHeader>
      </Fragment>
   )
}

export default Header
