import React, { Component } from 'react'
import App from './App.js'

import { BrowserRouter } from 'react-router-dom'

const AppWithRouter = () => (
   <BrowserRouter basename='/'>
      <App />
   </BrowserRouter>
)

export default function StartPoint() {
   return (
      <div>
         <AppWithRouter />
      </div>
   )
}
