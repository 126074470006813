import React, { useState, useEffect, useContext } from 'react'
import { Layout } from 'antd'

import { Typography } from 'antd'

import { Font } from '@react-pdf/renderer'

import { Redirect } from 'react-router-dom'

import '../App.css'

import { GlobalContext } from '../mainContext'

import jwt_decode from 'jwt-decode'

//import LoginPage from './loginPage'

//Main Layout
import Header from './layouts/Header'
import MainMenu from './layouts/MainMenu'
import Content from './layouts/Content'

import axios from 'axios'

//constant
import { APIURL } from '../constant'

// Register font
Font.register({
   family: 'THSarabunNew',
   src: require('../assets/font/THSarabunNew.ttf'),
})
Font.register({
   family: 'THSarabunNew-i',
   src: require('../assets/font/THSarabunNew Italic.ttf'),
})
Font.register({
   family: 'THSarabunNew-b',
   src: require('../assets/font/THSarabunNew Bold.ttf'),
})
Font.register({
   family: 'THSarabunNew-bi',
   src: require('../assets/font/THSarabunNew BoldItalic.ttf'),
})

export default function MainPage() {
   const [collapsed, setCollapsed] = useState(false)

   //sidemenu toggle
   let toggle = () => {
      if (collapsed === true) {
         setCollapsed(false)
      } else {
         setCollapsed(true)
      }
   }

   //context declare
   let { globalState, globalDispatch } = useContext(GlobalContext)

   const DoLogout = () => {
      globalDispatch({ type: 'LOG_OUT' })
   }

   async function getUser() {
      //check token -----------------------------------
      //get token from storage
      let thisToken = ''
      if ((await localStorage.getItem('app_token')) !== null) {
         thisToken =  (await localStorage.getItem('app_token'))
      }


      //call API
      axios
         .post(
            APIURL + `/api/v1/auth/checktoken`,
            { Token: thisToken },
            {
               mode: 'no-cors',
               headers: {
                  Authorization:  thisToken,
               },
            }
         )
         .then((res) => {
            let decoded = jwt_decode(res.data.token)

            globalDispatch({
               type: 'LOG_IN_SUCCESS',
               payload: {
                  username: decoded.unique_name,
                  token: res.data.token,
                  tokenData: decoded,
               },
            })
         })
         .catch((err) => {})
   }

   useEffect(() => {
      //effect 

      //load username and refresh token
      getUser()

      return () => {
         //cleanup
         Font.reset()
      }
   }, [globalDispatch])

   if (globalState.isAuthen != true && !globalState.loading) {
      return <Redirect to='/login' />
   }

   return (
      <React.Fragment>
         {
            //Main Page ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

            globalState.isAuthen && (
               <div>
                  {/* Main Layout-------------------------------------------------------------------- */}
                  <Layout theme='light'>
                     {/* Menu */}
                     <MainMenu />

                     <Layout>
                        {/* Header */}
                        <Header />

                        {/* Content ----------------------------------------------------------- */}
                        <Content />

                        {/* <Footer style={{ textAlign: 'center' }}>by Atchari Wacheesat</Footer> */}
                     </Layout>
                  </Layout>
               </div>
            )
         }
      </React.Fragment>
   )
}
