import * as React from 'react'

let GlobalContext = React.createContext()

let initialState = {
   UserName: null,
   UserPassword: '',
   AuthToken: null,
   TokenData: { fullname: '' },
   MenuCollapsed: false,
   isAuthen: false,
   loading: true,
}

let reducer = (state, action) => { 
   switch (action.type) {
      case 'setMenuKey':
         return { ...state, menuKey: action.payload }
      case 'setMenuCollapsed':
         return { ...state, MenuCollapsed: action.payload }
      case 'setIsAuthen':
         return { ...state, isAuthen: action.payload }
      case 'LOG_OUT':
         localStorage.removeItem('app_username')
         localStorage.removeItem('app_token')
         localStorage.removeItem('app_token_data')

         return {
            ...state,
            isAuthen: false,
            AuthToken: null,
            UserName: null,

            TokenData: { fullname: '' },
            loading: false,
         }
      case 'LOG_IN_SUCCESS':
         localStorage.setItem('app_username', action.payload.username)
         localStorage.setItem('app_token', 'Bearer ' + action.payload.token)
         localStorage.setItem(
            'app_token_data',
            JSON.stringify(action.payload.tokenData)
         )
         return {
            ...state,
            isAuthen: true,
            AuthToken: action.payload.token,
            UserName: action.payload.username,
            TokenData: action.payload.tokenData,
            loading: false,
         }
   }
}

function GlobalProvider(props) {
   let [globalState, globalDispatch] = React.useReducer(reducer, initialState)
   let value = { globalState, globalDispatch }

   return (
      <GlobalContext.Provider value={value}>
         {props.children}
      </GlobalContext.Provider>
   )
}

let GlobalConsumer = GlobalContext.Consumer

export { GlobalContext, GlobalProvider, GlobalConsumer }
