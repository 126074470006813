import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Form, Input, Button, Row, Col, Card, Typography, Alert } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Redirect } from 'react-router-dom'

import axios from 'axios'

//global context
import { GlobalContext } from '../mainContext'

import jwt_decode from 'jwt-decode'

//constant
import { APIURL } from '../constant'

const { Title, Text } = Typography

const LoginPage = (props) => {
   //context declare
   let { globalState, globalDispatch } = useContext(GlobalContext)

   const [loginOk, setLoginOk] = useState(false)
   const [alertVisible, setAlertVisible] = useState(false)

   //windows height
   const [windowHeight, setWindowHeight] = useState(window.innerHeight)

   const onFinish = async (values) => {
      //alert(values.username+' '+values.password)

      //Call Login API
      await axios
         .post(APIURL + `/api/v1/auth/login`, { UserName: values.username, Password: values.password }, { mode: 'no-cors' })
         .then(async (res) => {
            //Set context and localstorage for username and token

            let decoded = jwt_decode(res.data.token)
            //console.log(decoded)
            globalDispatch({
               type: 'LOG_IN_SUCCESS',
               payload: {
                  username: values.username,
                  token: res.data.token,
                  tokenData: decoded,
               },
            })

            setLoginOk(true)
            alert('Login Success!!')
         })
         .catch((err) => {
            setAlertVisible(true)
         })
   }

   useEffect(() => {
      //resize window
      window.addEventListener('resize', (e) => {
         setWindowHeight(window.innerHeight)
      })

      return () => {}
   }, [globalDispatch])

   if (loginOk) {
      return <Redirect to={'/dashboard'} />
   }

   return (
      <div style={{ backgroundColor: '#f0f2f5', height: '100vh' }}>
         <br />
         <br />
         <br />
         <br />

         <Row>
            <Col flex='auto'></Col>
            <Col flex='400px'>
               <div style={{ margin: 8 }}>
                  <Card style={{ boxShadow: '5px 5px 8px grey' }}>
                     <div
                        style={{
                           textAlign: 'center',
                           marginBottom: 20,
                           marginTop: 5,
                        }}
                     >
                        <img src={require('../assets/image/app-logo.png')} height='60' />
                     </div>
                     <div style={{ textAlign: 'center' }}>
                        <Title level={4}>Log In</Title>
                        {alertVisible && (
                           <Alert
                              showIcon
                              message={
                                 <Fragment>
                                    <Text type='danger'>Username and password didn't match.</Text>
                                 </Fragment>
                              }
                              type='error'
                           />
                        )}

                        <br />
                     </div>

                     <Form autoComplete='off' name='normal_login' className='login-form' onFinish={onFinish}>
                        <Form.Item
                           name='username'
                           rules={[
                              {
                                 required: true,
                                 message: 'Please input your Username!',
                              },
                           ]}
                        >
                           <Input
                              prefix={<UserOutlined className='site-form-item-icon' />}
                              placeholder='Username'
                              autoComplete='off'
                              autoFocus
                              autoCapitalize='none'
                           />
                        </Form.Item>
                        <Form.Item
                           name='password'
                           rules={[
                              {
                                 required: true,
                                 message: 'Please input your Password!',
                              },
                           ]}
                        >
                           <Input prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='Password' />
                        </Form.Item>

                        <Form.Item>
                           <Button type='primary' htmlType='submit' className='login-form-button'>
                              Log in
                           </Button>
                        </Form.Item>
                     </Form>
                  </Card>
               </div>
            </Col>
            <Col flex='auto'></Col>
         </Row>
      </div>
   )
}

export default LoginPage
