import React, { Component, useEffect ,useContext} from 'react'
import { Button, notification } from 'antd'
import './App.css'
import { Route, Switch, useLocation } from 'react-router-dom'
import MainPage from './screen/mainPage'
import LoginPage from './screen/loginPage'
import ErrorPage from './screen/ErrorPage'

import axios from 'axios'

import { GlobalContext } from './mainContext'

export default function AppCom() {
   //context declare
   let { globalState, globalDispatch } = useContext(GlobalContext)

   let location = useLocation()

   useEffect(() => {
      //axois intercept
      axios.interceptors.response.use(
         (response) => response,
         async (error) => {
            if (error.response.status != undefined) {
               if (error.response.status === 401 && location.pathname !== '/login') {
                  notification.open({
                     message: 'Error',
                     description: 'Authorization fail, Please login again.',
                     onClick: () => {
                        //console.log('Notification Clicked!');
                     },
                  })
                  await globalDispatch({ type: 'LOG_OUT' })
               }

               if (error.response.status === 500) {
                  notification.open({
                     message: 'Error',
                     description: 'Server Error!!',
                     onClick: () => {
                        //console.log('Notification Clicked!');
                     },
                  })
               }

               if (error.response.status === 403) {
                  notification.open({
                     message: 'Error',
                     description: 'Forbidden this function!!',
                     onClick: () => {
                        //console.log('Notification Clicked!');
                     },
                  })
               }
            }
         }
      )

      return () => {}
   }, [])

   return (
      <div>
         <Switch>
            <Route exact path='/login' component={LoginPage} />
            <Route exact path='/404' component={ErrorPage} />
            <Route component={MainPage} />
         </Switch>
      </div>
   )
}
