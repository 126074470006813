import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Alert, Layout, Menu, Button, notification, Form, Modal, Input, Typography } from 'antd'
import { DashboardOutlined, CalendarOutlined, ImportOutlined, ReconciliationOutlined, TableOutlined, FundOutlined , RiseOutlined } from '@ant-design/icons'

import axios from 'axios'
//constant
import { APIURL } from '../../constant'

import { Link, useLocation } from 'react-router-dom'

import { GlobalContext } from '../../mainContext'

const { SubMenu } = Menu
const AntSider = Layout.Sider
const { Text } = Typography

const layout = {
   labelCol: {
      span: 8,
   },
   wrapperCol: {
      span: 16,
   },
}
const tailLayout = {
   wrapperCol: {
      offset: 8,
      span: 16,
   },
}

const MainMenu = () => {
   //form
   const [form] = Form.useForm()

   //context declare
   let { globalState, globalDispatch } = useContext(GlobalContext)

   let location = useLocation()

   const [modalChangePwdVisible, setModalChangePwdVisible] = useState(false)
   const [menuKey, setMenuKey] = useState(null)

   //ChangePassword
   const onChangePassword = async (values) => {
      //alert(values.newPassword)

      //get token from storage
      let thisToken = ''
      if ((await localStorage.getItem('app_token')) !== null) {
         thisToken = localStorage.getItem('app_token')
      }

      //get user from storage
      let thisUser = ''
      if ((await localStorage.getItem('app_username')) !== null) {
         thisUser = await localStorage.getItem('app_username')
      }

      await axios
         .put(
            APIURL + `/api/v1/auth/changepassword`,
            {
               UserName: thisUser,
               Password: values.currentPassword,
               NewPassword: values.newPassword,
            },
            {
               mode: 'no-cors',
               headers: {
                  Authorization: thisToken,
               },
            }
         )
         .then((res) => {
            setModalChangePwdVisible(false)
            form.resetFields()

            if (res.status == 200) {
               notification.open({
                  message: 'Info',
                  description: 'Password is change!!',
                  onClick: () => {
                     //console.log('Notification Clicked!');
                  },
               })
            }
         })
         .catch((err) => {
            form.resetFields()
            globalDispatch('LOG_OUT')
            notification.open({
               message: 'Error',
               description: 'Can not changepassword',
               onClick: () => {
                  //console.log('Notification Clicked!');
               },
            })
         })
   }

   useEffect(() => {
      setMenuKey(location.pathname)

      return () => {}
   }, [globalDispatch])

   return (
      <Fragment>
         <Modal
            title='Change Password'
            visible={modalChangePwdVisible}
            onOk={(e) => {}}
            onCancel={(e) => {
               form.resetFields()
               setModalChangePwdVisible(false)
            }}
            footer={null}
         >
            <div>
               <Alert
                  message='Warning'
                  description='การเปลี่ยน password ขอให้มั่นใจว่าใส่ password เดิมถูกต้อง ถ้าใส่ผิดระบบจะทำการ logout อัตโนมัติเพื่อความปลอดภัย'
                  type='warning'
                  showIcon
               />
            </div>
            <br />
            <div>
               <Form {...layout} form={form} name='register' onFinish={onChangePassword} scrollToFirstError>
                  <Form.Item
                     name='currentPassword'
                     label='Current Password'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your current password!',
                        },
                     ]}
                  >
                     <Input.Password />
                  </Form.Item>

                  <Form.Item
                     name='newPassword'
                     label='New Password'
                     rules={[
                        {
                           required: true,
                           message: 'Please input your new password!',
                        },
                     ]}
                     hasFeedback
                  >
                     <Input.Password />
                  </Form.Item>

                  <Form.Item
                     name='confirm'
                     label='Confirm Password'
                     dependencies={['newPassword']}
                     hasFeedback
                     rules={[
                        {
                           required: true,
                           message: 'Please confirm your new password!',
                        },
                        ({ getFieldValue }) => ({
                           validator(rule, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                 return Promise.resolve()
                              }
                              return Promise.reject('The two passwords that you entered do not match!')
                           },
                        }),
                     ]}
                  >
                     <Input.Password />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                     <Button type='primary' htmlType='submit'>
                        Submit
                     </Button>
                     <Button
                        htmlType='button'
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                           form.resetFields()
                        }}
                     >
                        Reset
                     </Button>
                  </Form.Item>
               </Form>
            </div>
         </Modal>

         <AntSider
            //trigger={null}
            collapsible
            //collapsed={collapsed}
            theme='light'
            width='250'
            breakpoint='xl'
            collapsedWidth='0'
            onBreakpoint={(broken) => {
               //setCollapsed(broken)
            }}
            onCollapse={(collapsed, type) => {
               //setCollapsed(collapsed)
               //console.log(collapsed, type);
            }}
            style={{ boxShadow: '5px 0px 8px grey' }}
            zeroWidthTriggerStyle={{
               boxShadow: '2px 2px 4px grey',
            }}
         >
            <div className='logo' />

            {/*Logo */}
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
               <img src={require('../../assets/image/app-logo.png')} height='50' />
            </div>

            <br />
            <div style={{ textAlign: 'center' }}>
               <div>
                  <Text strong style={{ color: '#FFFFFF' }}>
                     {globalState.TokenData !== null ? globalState.TokenData.given_name : '-'}
                  </Text>
               </div>

               <div>
                  <Button
                     size='small'
                     type='link'
                     color='#96A3AE'
                     onClick={() => {
                        //show change password modal
                        setModalChangePwdVisible(true)
                     }}
                  >
                     <span style={{ color: '#96A3AE' }}>Change Password</span>
                  </Button>
               </div>
            </div>
            <br />

            {/* Menu Layout */}
            <Menu
               theme='light'
               mode='inline'
               defaultSelectedKeys={[location.pathname]}
               selectedKeys={menuKey}
               style={{ backgroundColor: '#33F4D67', border: 0 }}
            >
               <Menu.Item
                  key='/dashboard'
                  onClick={() => {
                     setMenuKey('/dashboard')
                  }}
               >
                  {location.pathname === '/dashboard' && (
                     <Link to='/dashboard'>
                        <span style={{ color: '#FFFFFF' }}>
                           <DashboardOutlined />
                           Dashboard
                        </span>
                     </Link>
                  )}

                  {location.pathname !== '/dashboard' && (
                     <Link to='/dashboard'>
                        <span>
                           <DashboardOutlined />
                           Dashboard
                        </span>
                     </Link>
                  )}
               </Menu.Item>

               {/* Monitor */}
               <Menu.Item
                  key='/monitor'
                  onClick={() => {
                     setMenuKey('/monitor')
                  }}
               >
                  {location.pathname === '/monitor' && (
                     <Link to='/monitor'>
                        <span style={{ color: '#FFFFFF' }}>
                           <RiseOutlined />
                           Monitor
                        </span>
                     </Link>
                  )}

                  {location.pathname !== '/monitor' && (
                     <Link to='/monitor'>
                        <span>
                           <RiseOutlined />
                           Monitor
                        </span>
                     </Link>
                  )}
               </Menu.Item>


               {/* Report */}

               <Menu.Item
                  key='/report'
                  onClick={() => {
                     setMenuKey('/report')
                  }}
               >
                  {location.pathname === '/report' && (
                     <Link to='/report'>
                        <span style={{ color: '#FFFFFF' }}>
                           <ReconciliationOutlined />
                           รายงาน
                        </span>
                     </Link>
                  )}

                  {location.pathname !== '/report' && (
                     <Link to='/report'>
                        <span>
                           <ReconciliationOutlined />
                           รายงาน
                        </span>
                     </Link>
                  )}
               </Menu.Item>

               {/* ข้อมูล */}
               <Menu.Item
                  key='/query_table'
                  onClick={() => {
                     setMenuKey('/query_table')
                  }}
               >
                  {location.pathname === '/query_table' && (
                     <Link to='/query_table'>
                        <span style={{ color: '#FFFFFF' }}>
                           <ReconciliationOutlined />
                           ข้อมูล
                        </span>
                     </Link>
                  )}

                  {location.pathname !== '/query_table' && (
                     <Link to='/query_table'>
                        <span>
                           <ReconciliationOutlined />
                           ข้อมูล
                        </span>
                     </Link>
                  )}
               </Menu.Item>

               {/* Plan */}
               <SubMenu
                  title={
                     <span className='submenu-title-wrapper'>
                        <CalendarOutlined />
                        Plan
                     </span>
                  }
               >
                  {/* 
                    <Menu.Item
                      key='AggregatePlan'
                      onClick={() => {
                        globalDispatch({
                          type: "setMenuKey",
                          payload: "AggregatePlan",
                        })
                        //setMenuKey('AggregatePlan')
                        localStorage.setItem("menuKey", "AggregatePlan")
                      }}
                    >
                      {globalState.menuKey === "AggregatePlan" && (
                        <span style={{ color: "#FFFFFF" }}>Aggregate</span>
                      )}

                      {globalState.menuKey !== "AggregatePlan" && (
                        <span>Aggregate</span>
                      )}
                    </Menu.Item>
                     */}

                  {(globalState.UserName === 'admin' ||
                     globalState.UserName === 'somwang.ru' ||
                     globalState.UserName === 'atchari.wa' ||
                     globalState.UserName === 'wannaporn.th') && (
                     <Menu.Item
                        key='/plan/mpsmain'
                        onClick={() => {
                           setMenuKey('/plan/mpsmain')
                        }}
                     >
                        {location.pathname === '/plan/mpsmain' && (
                           <Link to='/plan/mpsmain'>
                              <span style={{ color: '#FFFFFF' }}>MPS</span>
                           </Link>
                        )}

                        {location.pathname !== '/plan/mpsmain' && (
                           <Link to='/plan/mpsmain'>
                              <span>MPS</span>
                           </Link>
                        )}
                     </Menu.Item>
                  )}

                  <Menu.Item
                     key='/plan/setting'
                     onClick={() => {
                        setMenuKey('/plan/setting')
                     }}
                  >
                     {location.pathname === '/plan/setting' && (
                        <Link to='/plan/setting'>
                           <span style={{ color: '#FFFFFF' }}>Setting</span>
                        </Link>
                     )}

                     {location.pathname !== '/plan/setting' && (
                        <Link to='/plan/setting'>
                           <span>Setting</span>
                        </Link>
                     )}
                  </Menu.Item>

                  <Menu.Item
                     key='/plan/mc_calendar'
                     onClick={() => {
                        setMenuKey('/plan/mc_calendar')
                     }}
                  >
                     {location.pathname === '/plan/mc_calendar' && (
                        <Link to='/plan/mc_calendar'>
                           <span style={{ color: '#FFFFFF' }}>Calendar</span>
                        </Link>
                     )}

                     {location.pathname !== '/plan/mc_calendar' && (
                        <Link to='/plan/mc_calendar'>
                           <span>Calendar</span>
                        </Link>
                     )}
                  </Menu.Item>

                  <Menu.Item
                     key='/plan/bom'
                     onClick={() => {
                        setMenuKey('/plan/bom')
                     }}
                  >
                     {location.pathname === '/plan/bom' && (
                        <Link to='/plan/bom'>
                           <span style={{ color: '#FFFFFF' }}>Bom</span>
                        </Link>
                     )}

                     {location.pathname !== '/plan/bom' && (
                        <Link to='/plan/bom'>
                           <span>Bom</span>
                        </Link>
                     )}
                  </Menu.Item>

                  {/* 
                    <Menu.Item
                      key='RCCP'
                      onClick={() => {
                        globalDispatch({ type: "setMenuKey", payload: "RCCP" })
                        //setMenuKey('RCCP')
                        localStorage.setItem("menuKey", "RCCP")
                      }}
                    >
                      {globalState.menuKey === "RCCP" && (
                        <span style={{ color: "#FFFFFF" }}>RCCP</span>
                      )}

                      {globalState.menuKey !== "RCCP" && <span>RCCP</span>}
                    </Menu.Item>
 */}
               </SubMenu>

               {/* Import */}
               <SubMenu
                  title={
                     <span className='submenu-title-wrapper'>
                        <ImportOutlined />
                        Import Data
                     </span>
                  }
               >
                  {/* From Excel */}
                  <SubMenu title={<span className='submenu-title-wrapper'>Excel Template</span>}>
                     <Menu.Item
                        key='/importdata/agingdoc'
                        onClick={() => {
                           setMenuKey('/importdata/agingdoc')
                        }}
                     >
                        {location.pathname === '/importdata/agingdoc' && (
                           <Link to='/importdata/agingdoc'>
                              <span style={{ color: '#FFFFFF' }}>ใบอบแข็ง</span>
                           </Link>
                        )}

                        {location.pathname !== '/importdata/agingdoc' && (
                           <Link to='/importdata/agingdoc'>
                              <span>ใบอบแข็ง</span>
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item
                        key='/importdata/dieindex'
                        onClick={() => {
                           setMenuKey('/importdata/dieindex')
                        }}
                     >
                        {location.pathname === '/importdata/dieindex' && (
                           <Link to='/importdata/dieindex'>
                              <span style={{ color: '#FFFFFF' }}>Die Index</span>
                           </Link>
                        )}

                        {location.pathname !== '/importdata/dieindex' && (
                           <Link to='/importdata/dieindex'>
                              <span>Die Index</span>
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item
                        key='/importdata/urgentid'
                        onClick={() => {
                           setMenuKey('/importdata/urgentid')
                        }}
                     >
                        {location.pathname === '/importdata/urgentid' && (
                           <Link to='/importdata/urgentid'>
                              <span style={{ color: '#FFFFFF' }}>ID งานด่วน</span>
                           </Link>
                        )}

                        {location.pathname !== '/importdata/urgentid' && (
                           <Link to='/importdata/urgentid'>
                              <span>ID งานด่วน</span>
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item
                        key='/importdata/productionstatus'
                        onClick={() => {
                           setMenuKey('/importdata/productionstatus')
                        }}
                     >
                        {location.pathname === '/importdata/productionstatus' && (
                           <Link to='/importdata/productionstatus'>
                              <span style={{ color: '#FFFFFF' }}>สถานะ Production</span>
                           </Link>
                        )}

                        {location.pathname !== '/importdata/productionstatus' && (
                           <Link to='/importdata/productionstatus'>
                              <span>สถานะ Production</span>
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item
                        key='/importdata/extschedule'
                        onClick={() => {
                           setMenuKey('/importdata/extschedule')
                        }}
                     >
                        {location.pathname === '/importdata/extschedule' && (
                           <Link to='/importdata/extschedule'>
                              <span style={{ color: '#FFFFFF' }}>Ext Schedule</span>
                           </Link>
                        )}

                        {location.pathname !== '/importdata/extschedule' && (
                           <Link to='/importdata/extschedule'>
                              <span>Ext Schedule</span>
                           </Link>
                        )}
                     </Menu.Item>

                     <Menu.Item
                        key='/importdata/millbalance'
                        onClick={() => {
                           setMenuKey('/importdata/millbalance')
                        }}
                     >
                        {location.pathname === '/importdata/millbalance' && (
                           <Link to='/importdata/millbalance'>
                              <span style={{ color: '#FFFFFF' }}>Mill Stock</span>
                           </Link>
                        )}

                        {location.pathname !== '/importdata/millbalance' && (
                           <Link to='/importdata/millbalance'>
                              <span>Mill Stock</span>
                           </Link>
                        )}
                     </Menu.Item>


                  </SubMenu>

                  <Menu.Item
                     key='/importdata/from_mta'
                     onClick={() => {
                        setMenuKey('/importdata/from_mta')
                     }}
                  >
                     {location.pathname === '/importdata/from_mta' && (
                        <Link to='/importdata/from_mta'>
                           <span style={{ color: '#FFFFFF' }}>From MTA</span>
                        </Link>
                     )}

                     {location.pathname !== '/importdata/from_mta' && (
                        <Link to='/importdata/from_mta'>
                           <span>From MTA</span>
                        </Link>
                     )}
                  </Menu.Item>
               </SubMenu>

               {/* Report */}

               <Menu.Item
                  key='/management'
                  onClick={() => {
                     setMenuKey('/management')
                  }}
               >
                  {location.pathname === '/management' && (
                     <Link to='/report'>
                        <span style={{ color: '#FFFFFF' }}>
                           <FundOutlined />
                           Management Zone
                        </span>
                     </Link>
                  )}

                  {location.pathname !== '/management' && (
                     <Link to='/management'>
                        <span>
                           <FundOutlined />
                           Management Zone
                        </span>
                     </Link>
                  )}
               </Menu.Item>

               {/*Setting */}
               {/* 
               <SubMenu
                  title={
                     <span className='submenu-title-wrapper'>
                        <SettingOutlined />
                        Setting
                     </span>
                  }
               >
                  <Menu.Item
                     key='USER_SETTING'
                     onClick={() => {
                        globalDispatch({
                           type: 'setMenuKey',
                           payload: 'USER_SETTING',
                        })
                        //setMenuKey('PLANNING_PARAMETER')
                        localStorage.setItem('menuKey', 'USER_SETTING')
                     }}
                  >
                     {globalState.menuKey === 'USER_SETTING' && (
                        <span style={{ color: '#FFFFFF' }}>Users</span>
                     )}

                     {globalState.menuKey !== 'USER_SETTING' && (
                        <span>Users</span>
                     )}
                  </Menu.Item>

                  <Menu.Item
                     key='PLANNING_PARAMETER'
                     onClick={() => {
                        globalDispatch({
                           type: 'setMenuKey',
                           payload: 'PLANNING_PARAMETER',
                        })
                        //setMenuKey('PLANNING_PARAMETER')
                        localStorage.setItem('menuKey', 'PLANNING_PARAMETER')
                     }}
                  >
                     {globalState.menuKey === 'PLANNING_PARAMETER' && (
                        <span style={{ color: '#FFFFFF' }}>
                           Planning Parameter
                        </span>
                     )}

                     {globalState.menuKey !== 'PLANNING_PARAMETER' && (
                        <span>Planning Parameter</span>
                     )}
                  </Menu.Item>
               </SubMenu>
                */}
            </Menu>
         </AntSider>
      </Fragment>
   )
}

export default MainMenu
