//Node Express
//export const APIURL = 'http://localhost:8081'
//export const APIURL = 'http://192.168.2.30:8081'
//export const APIURL = 'http://plnwebapi.alumetgroup.com:8081' //not service
//export const APIURL = 'https://plnwebapi.alumetgroup.com:5443' //as service

//Dot Net Core
// export const APIURL = 'http://localhost:5000' 
// export const APIURL = 'https://pln.alumetgroup.com:5443' 
//export const APIURL = 'http://pln.alumetgroup.com:5000' 
export const APIURL = 'http://pln.alumetgroup.com:8007' 

export const ExportButtonStyle = {
   border: 0,
   backgroundColor: '#07A0E7',
   color: '#FFFFFF',
   boxShadow: '0 2px 0 rgba(0, 0, 0, 0.045)',
   borderRadius: '2px',
   cursor: 'pointer', 
   outline: 'none',
   transition: '0.4s',
}

export const ExportButtonHoverStyle = {
   border: 0,
   backgroundColor: '#2fbdf5',
   color: '#FFFFFF',
   boxShadow: '0 2px 0 rgba(0, 0, 0, 0.045)',
   borderRadius: '2px',
   cursor: 'pointer',
   outline: 'none',
   transition: '0.4s',
}

export const ExportLabelStyle = { marginLeft: 8, marginRight: 8, marginTop: 4, marginBottom: 4 }
