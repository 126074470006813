import React, { Fragment, useContext, useState, useEffect, Suspense } from 'react'
import { Layout, Menu , Spin } from 'antd'

import { Route, Switch, useLocation, Redirect } from 'react-router-dom'

import { GlobalContext } from '../../mainContext'

//===views
//views 404 Page
import ErrorPage from '../ErrorPage'
//views test
import ContextDemo from '../views/dashboard/component/useContextDemo'
//views Seting group
import UserSetting from '../views/setting/UserSetting'

const { SubMenu } = Menu
const AntContent = Layout.Content

//lazy component load -----------------------------------------------------------
//views dashboard group
const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'))
//views plan group
const GanttDemo = React.lazy(() => import('../views/plan/ganttDemo'))
const MpsMain = React.lazy(() => import('../views/plan/MpsMain'))
const MpsSetting = React.lazy(() => import('../views/plan/MpsSetting'))
const McCalendar = React.lazy(() => import('../views/plan/McCalendar'))
const Bom = React.lazy(() => import('../views/plan/Bom'))
//views Report group
const Report = React.lazy(() => import('../views/report/Report'))
const CoByDateByCustPivot = React.lazy(() => import('../views/report/report/sales/CoByDateByCustPivot'))
const DoByDateByCustPivot = React.lazy(() => import('../views/report/report/sales/DoByDateByCustPivot'))
const BacklogDataPivot = React.lazy(() => import('../views/report/report/sales/BacklogDataPivot'))
const BacklogByCustBySKU = React.lazy(() => import('../views/report/report/sales/BacklogByCustBySKU'))
const BacklogByCo = React.lazy(() => import('../views/report/report/sales/BacklogByCo'))
const BacklogByCustBySKUShowBookBalance = React.lazy(() => import('../views/report/report/sales/BacklogByCustBySKUShowBookBalance'))
const BacklogByCustBySKUShowBookBalanceWithPd = React.lazy(() => import('../views/report/report/sales/BacklogByCustBySKUShowBookBalanceWithPd'))
const BacklogByCustBySKUShowBookBalanceWithPdAndProject = React.lazy(() => import('../views/report/report/sales/BacklogByCustBySKUShowBookBalanceWithPdAndProject'))
const FgListByPress = React.lazy(() => import('../views/report/report/planning/FgListByPress'))
const FgListVsCatalog = React.lazy(() => import('../views/report/report/planning/FgListVsCatalog'))
const DiscountBy3MonthCoDoByCust = React.lazy(() => import('../views/report/report/sales/DiscountBy3MonthCoDoByCust'))
const CustomerCreditQuota = React.lazy(() => import('../views/report/report/sales/CustomerCreditQuota'))
const StockByColorGroupByDate = React.lazy(() => import('../views/report/report/stock/StockByColorGroupByDate'))

const BacklogByCustBySKUInTable = React.lazy(() => import('../views/report/report/sales/BacklogByCustBySKUInTable'))
//views import data group
const AgingDocImport = React.lazy(() => import('../views/import_data/AgingDocImport'))
const DieIndexImport = React.lazy(() => import('../views/import_data/DieIndexImport'))
const UrgentIdImport = React.lazy(() => import('../views/import_data/UrgentIdImport'))
const ProductionStatusImport = React.lazy(() => import('../views/import_data/ProductionStatusImport'))
const ExtScheduleImport = React.lazy(() => import('../views/import_data/ExtScheduleImport'))
const MillBalanceImport = React.lazy(() => import('../views/import_data/MillBalanceImport'))
const Execute = React.lazy(() => import('../views/import_data/Execute'))
//views query table group
const QueryData = React.lazy(() => import('../views/query_table/QueryData'))
const BacklogTable = React.lazy(() => import('../views/query_table/BacklogTable'))
const BacklogBySKUTable = React.lazy(() => import('../views/query_table/BacklogBySKUTable'))
const StockTable = React.lazy(() => import('../views/query_table/StockTable'))
const StockBySKUTable = React.lazy(() => import('../views/query_table/StockBySKUTable'))
const DieIndexTable = React.lazy(() => import('../views/query_table/DieIndexTable'))
const AgingDocTable = React.lazy(() => import('../views/query_table/AgingDocTable'))
const CatalogTable = React.lazy(() => import('../views/query_table/CatalogTable'))
const TechDwgTable = React.lazy(() => import('../views/query_table/TechDwgTable'))
const MillSkuMinDuedate = React.lazy(() => import('../views/query_table/MillSkuMinDuedate'))
const Top10SkuOfVipCustomer = React.lazy(() => import('../views/query_table/Top10SkuOfVipCustomer'))
const DuedateByDate = React.lazy(() => import('../views/query_table/DuedateByDate'))
//views management special zone
const Management = React.lazy(() => import('../views/management/Management'))
const ImportCogs = React.lazy(() => import('../views/management/ImportCogs'))
const ImportMachineCost = React.lazy(() => import('../views/management/ImportMachineCost'))
const CogsByCustLast6MonthFab = React.lazy(() => import('../views/management/CogsByCustLast6MonthFab'))
const CogsByCustLast6MonthInd = React.lazy(() => import('../views/management/CogsByCustLast6MonthInd'))
const GpByCustByProjectByDateFab = React.lazy(() => import('../views/management/GpByCustByProjectByDateFab'))
const DoCogsByDateFab = React.lazy(() => import('../views/management/DoCogsByDateFab'))
//monitor
const VisionList = React.lazy(() => import('../views/monitor/VisionList'))
const SmartXList = React.lazy(() => import('../views/monitor/SmartXList'))
const ThinProfileList = React.lazy(() => import('../views/monitor/ThinProfileList'))
const MonitorMenu = React.lazy(() => import('../views/monitor/MonitorMenu'))



const Content = () => {
   //context declare
   let { globalState, globalDispatch } = useContext(GlobalContext)

   const ContentSubPx = 82
   const [ContentH, setContentH] = useState(window.innerHeight - ContentSubPx)
   let location = useLocation()

   useEffect(() => {
      //resize window
      window.addEventListener('resize', (e) => {
         setContentH(window.innerHeight - ContentSubPx)
      })

      return () => {}
   }, [globalDispatch])

   return (
      <Fragment>
         <AntContent
            style={{
               margin: '16px 16px',
               padding: '16',
               minHeight: ContentH,
            }}
         >
            <div
               style={{
                  paddingTop:10,
                  paddingLeft: location.pathname === '/dashboard'? 0 : 10,
                  paddingRight: location.pathname === '/dashboard'? 0 : 10,
                  paddingBottom: location.pathname === '/dashboard'? 0 : 10,
                  background: location.pathname === '/dashboard' ? '#f0f2f5' : '#FFFFFF',
                  minHeight: ContentH,
                  boxShadow: location.pathname === '/dashboard' ? '0px 0px 0px grey' : '5px 5px 8px grey',
               }}
            >
               <Suspense fallback={<Spin tip="Loading..." />}>
                  <Switch>
                     <Route exact path='/dashboard' component={Dashboard} />

                     {/* Monitor */}
                     <Route exact path='/monitor' component={MonitorMenu} />
                     <Route exact path='/monitor/vision_list' component={VisionList} />
                     <Route exact path='/monitor/smartx' component={SmartXList} />
                     <Route exact path='/monitor/thin_profile' component={ThinProfileList} />

                     {/* REPORT */}
                     {/* ------------Sale */}
                     <Route exact path='/report' component={Report} />
                     <Route exact path='/report/sales/backlogbycustbysku' component={BacklogByCustBySKU} />
                     <Route exact path='/report/sales/backlogbycustbyskushowbookbalance' component={BacklogByCustBySKUShowBookBalance} />
                     <Route exact path='/report/sales/backlogbycustbyskushowbookbalancewithpd' component={BacklogByCustBySKUShowBookBalanceWithPd} />
                     <Route exact path='/report/sales/backlogbycustbyskushowbookbalancewithpdandproject' component={BacklogByCustBySKUShowBookBalanceWithPdAndProject} />
                     <Route exact path='/report/sales/backlogdatapivot' component={BacklogDataPivot} />
                     <Route exact path='/report/sales/cobydatebycustpivot' component={CoByDateByCustPivot} />
                     <Route exact path='/report/sales/dobydatebycustpivot' component={DoByDateByCustPivot} />
                     <Route exact path='/report/sales/backlogbycustbysku' component={BacklogByCustBySKU} />
                     <Route exact path='/report/sales/backlogbyco' component={BacklogByCo} />
                     <Route exact path='/report/sales/backlogbycustbyskuintable' component={BacklogByCustBySKUInTable} />
                     <Route exact path='/report/sales/discountby3monthcodobycust' component={DiscountBy3MonthCoDoByCust} />
                     <Route exact path='/report/sales/customercreditquota' component={CustomerCreditQuota} />
                  

                     
                     

                     {/* ------------Planning */}
                     <Route exact path='/report/planning/fglistbypress' component={FgListByPress} />
                     <Route exact path='/report/planning/fglistvscatalog' component={FgListVsCatalog} />

                     {/* -------------Stock*/}
                     <Route exact path='/report/stock/stockbycolorgroupbydate' component={StockByColorGroupByDate} />


                     {/* QUERY_TABLE */}
                     <Route exact path='/query_table' component={QueryData} />
                     <Route exact path='/query_table/backlogbyskutable' component={BacklogBySKUTable} />
                     <Route exact path='/query_table/backlogtable' component={BacklogTable} />
                     <Route exact path='/query_table/stockbyskutable' component={StockBySKUTable} />
                     <Route exact path='/query_table/stocktable' component={StockTable} />
                     <Route exact path='/query_table/dieindex' component={DieIndexTable} />
                     <Route exact path='/query_table/agingdoc' component={AgingDocTable} />
                     <Route exact path='/query_table/catalog' component={CatalogTable} />
                     <Route exact path='/query_table/techdwg' component={TechDwgTable} />
                     <Route exact path='/query_table/millskuminduedate' component={MillSkuMinDuedate} />
                     <Route exact path='/query_table/top10skuofvipcustomer' component={Top10SkuOfVipCustomer} />
                     <Route exact path='/query_table/duedatebydate' component={DuedateByDate} />

                  

                     {/* PLAN */}
                     <Route exact path='/plan/mpsmain' component={MpsMain} />
                     <Route exact path='/plan/setting' component={MpsSetting} />
                     <Route exact path='/plan/mc_calendar' component={McCalendar} />
                     <Route exact path='/plan/bom' component={Bom} />

                     {/* IMPORT DATA */}
                     <Route exact path='/importdata/agingdoc' component={AgingDocImport} />
                     <Route exact path='/importdata/dieindex' component={DieIndexImport} />
                     <Route exact path='/importdata/urgentid' component={UrgentIdImport} />
                     <Route exact path='/importdata/productionstatus' component={ProductionStatusImport} />
                     <Route exact path='/importdata/extschedule' component={ExtScheduleImport} />
                     <Route exact path='/importdata/millbalance' component={MillBalanceImport} />
                     <Route exact path='/importdata/from_mta' component={Execute} />


                     {/* Management */}
                     <Route exact path='/management' component={Management} />
                     <Route exact path='/management/importcogs' component={ImportCogs} />
                     <Route exact path='/management/importmachinecost' component={ImportMachineCost} />
                     <Route exact path='/management/cogsbycustlast6monthfab' component={CogsByCustLast6MonthFab} />
                     <Route exact path='/management/cogsbycustlast6monthind' component={CogsByCustLast6MonthInd} />
                     <Route exact path='/management/gpbycustbyprojectbydatefab' component={GpByCustByProjectByDateFab} />
                     <Route exact path='/management/docogsbydatefab' component={DoCogsByDateFab} />

                     
                     

                     

                     <Route exact path='/'>
                        <Redirect to='/dashboard' />
                     </Route>

                     <Route component={ErrorPage} />
                  </Switch>
               </Suspense>
            </div>
         </AntContent>
      </Fragment>
   )
}

export default Content
