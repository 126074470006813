import React, { useState, useEffect, useContext } from 'react'
import { Button, Row, Col, Typography, Alert, notification, Table, Switch, Input, Popconfirm } from 'antd'

import { UserAddOutlined, EditOutlined, DeleteOutlined, UndoOutlined, CheckOutlined } from '@ant-design/icons'

import axios from 'axios'

import { GlobalContext } from '../../../mainContext'

//constant
import { APIURL } from '../../../constant'

const { Text } = Typography

const tableLineHeight = 0.8

export default function UserSetting(props) {
   //columns
   const columns = [
      {
         title: 'User Name',
         dataIndex: 'UserName',
         key: 'UserName',
         render: (v, record) => <div>{v}</div>,
      },
      {
         title: 'Full Name',
         dataIndex: 'UserFullName',
         key: 'UserFullName',
         render: (v, record) => {
            return (
               <div>
                  {record.editMode === false && <div>{v}</div>}
                  {record.editMode === true && (
                     <div>
                        <Input value={v} />
                     </div>
                  )}
               </div>
            )
         },
      },
      {
         title: 'Email',
         dataIndex: 'UserEmail',
         key: 'UserEmail',
         render: (v, record) => {
            return (
               <div>
                  {record.editMode === false && <div>{v}</div>}
                  {record.editMode === true && (
                     <div>
                        <Input value={v} />
                     </div>
                  )}
               </div>
            )
         },
      },
      {
         title: 'Role',
         dataIndex: 'RoleName',
         key: 'RoleName',
         render: (v, record) => <div>{v}</div>,
      },
      {
         title: 'Active',
         dataIndex: 'UserStatusCode',
         key: 'UserStatusCode',
         align: 'center',
         render: (v, record) => (
            <div>
               <Switch checked={v === '01' ? true : false} size='small' />
            </div>
         ),
      },
      {
         title: '',
         //dataIndex: 'Edit',
         key: 'Edit',
         align: 'center',
         render: (v, record) => (
            <div>
               {record.editMode === false && (
                  <Button
                     //Edit Button
                     size='small'
                     type='link'
                     onClick={() => {
                        let tempData = []

                        userData.forEach((v, i) => {
                           let tempValue = v

                           if (v.key === record.key) {
                              tempValue.editMode = true
                           } else {
                              tempValue.editMode = false
                           }

                           tempData.push(tempValue)
                        })

                        setUserData(tempData)
                     }}
                  >
                     <EditOutlined />
                  </Button>
               )}

               {record.editMode === true && (
                  <div>
                     <Button
                        //Undo Button
                        size='small'
                        type='link'
                        onClick={() => {
                           let tempData = []

                           userData.forEach((v, i) => {
                              let tempValue = v

                              tempValue.editMode = false

                              tempData.push(tempValue)
                           })

                           setUserData(tempData)
                        }}
                     >
                        <UndoOutlined />
                     </Button>

                     <Button
                        //Save data Button
                        size='small'
                        type='link'
                        onClick={() => {
                           let tempData = []

                           userData.forEach((v, i) => {
                              let tempValue = v

                              tempValue.editMode = false

                              tempData.push(tempValue)
                           })

                           setUserData(tempData)
                        }}
                     >
                        <CheckOutlined />
                     </Button>
                  </div>
               )}
            </div>
         ),
      },
      {
         title: '',
         //dataIndex: 'Edit',
         key: 'Delete',
         align: 'center',
         render: (v, record) => (
            <div>
               <Popconfirm title={`Sure to delete "${record.UserFullName}" ?`} onConfirm={() => {}}>
                  <Button size='small' type='link'>
                     <DeleteOutlined />
                  </Button>
               </Popconfirm>
            </div>
         ),
      },
   ]

   //state declare
   const [userData, setUserData] = useState([])
   const [formState, setFormState] = useState('ShowData')

   //for component height by window height
   const TableSubPx = 260
   const [tableHeight, setTableHeight] = useState(window.innerHeight - TableSubPx)

   const loadData = async () => {
      //get token from storage
      let thisToken = ''
      if ((await localStorage.getItem('app_token')) !== null) {
         thisToken = localStorage.getItem('app_token')
      }

      //call API
      axios
         .get(
            APIURL + `/api/v1/setting/users`,
            {
               headers: {
                  Authorization: thisToken,
               },
            },
            { mode: 'no-cors' }
         )
         .then((res) => {
            //alert(res.data)

            let result = res.data

            let i = 0
            for (i = 0; i < result.length; i++) {
               result[i].key = i
               result[i].editMode = false
            }

            //alert(JSON.stringify(result))

            setUserData(result)
         })
         .catch((err) => {
            notification.open({
               message: 'Error',
               description: 'Can not load data.',
               onClick: () => {
                  //console.log('Notification Clicked!');
               },
            })
         })
   }

   useEffect(() => {
      //effect

      //resize window
      window.addEventListener('resize', (e) => {
         setTableHeight(window.innerHeight - TableSubPx)
      })

      loadData()

      return () => {
         //cleanup
      }
   }, [])

   //context declare
   let { globalState, globalDispatch } = useContext(GlobalContext)

   return (
      <div>
         <Row>
            <Col>
               <Text strong style={{ marginLeft: 20 }}>
                  Users Setting >
               </Text>
            </Col>
         </Row>

         <Row>
            <Col>
               <br />
               {/*JSON.stringify(userData)*/}
            </Col>
         </Row>

         {formState === 'ShowData' && (
            <div>
               <Row>
                  <Col>
                     <Button type='primary'>
                        <UserAddOutlined />
                        New User
                     </Button>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <br />
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <Table
                        bordered
                        dataSource={userData}
                        columns={columns}
                        pagination={false}
                        //scroll={{ x: 1500, y: tableHeight }}
                        size='small'
                     />
                  </Col>
               </Row>
               <br />
            </div>
         )}
      </div>
   )
}
